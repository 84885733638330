import React from "react"
import { navigateTo } from "gatsby-link"

class Intro extends React.Component {
  state = {
    items: [
      <div>
        There are currently <span>US$46 Trillion in AUM</span> employing
        Responsible and Environmental, Social and Governance (“ESG”) strategies
        around the globe.
      </div>,
      <div>
        But the <span>"S"</span> – the social factors in ESG, if present, are
        based on anecdotal data.
      </div>,
      <div>
        Without the <span>S</span>, there is no <span>ESG</span>.
      </div>,
      <div>The entire marketing is facing the risk of impact-washing ESG.</div>,
      <div>
        <span>THIS IS AN ENORMOUS PROBLEM.</span>
      </div>,
      <div>
        <span>Until Now.</span>
      </div>,
    ],
    currentItemIndex: 0,
  }

  componentDidMount() {
    this.animate()
  }

  animate() {
    setTimeout(() => {
      if (this.state.currentItemIndex >= this.state.items.length - 1) {
        setTimeout(() => {
          this.setState({ currentItemIndex: this.state.currentItemIndex + 1 })
          setTimeout(() => {
            navigateTo("home")
          }, 750)
        }, 4000)
        return
      }
      this.setState({ currentItemIndex: this.state.currentItemIndex + 1 })
      this.animate()
    }, 6000)
  }

  renderItems() {
    return this.state.items.map((item, index) => {
      return (
        <div
          key={index}
          className={
            index === this.state.currentItemIndex
              ? "content-item content-item--visible"
              : "content-item"
          }
        >
          {item}
        </div>
      )
    })
  }
  render() {
    return (
      <div className="intro">
        <div className="video-bg">
          <video
            poster="https://firebasestorage.googleapis.com/v0/b/sfactor-website-staging.appspot.com/o/intro.jpg?alt=media"
            autoPlay
            loop
            playsInline
            muted
          >
            <source
              src="https://firebasestorage.googleapis.com/v0/b/sfactor-website-staging.appspot.com/o/intro.mp4?alt=media"
              type="video/mp4"
            />
            <source
              src="https://firebasestorage.googleapis.com/v0/b/sfactor-website-staging.appspot.com/o/intro.webm?alt=media"
              type="video/webm"
            />
          </video>
          <div className="video-cover"></div>
        </div>
        <div className="content">{this.renderItems()}</div>
      </div>
    )
  }
}

export { Intro }
