import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Section } from "../components/common"
import { Intro } from "../components/Intro"

class LandingPage extends Component {
  render() {
    return (
      <>
        <Layout>
          <SEO title="Intro" keywords={[]} />
          <Intro></Intro>
        </Layout>
      </>
    )
  }
}

export default LandingPage
